/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

require("typeface-merriweather")
require("typeface-open-sans")
require("react-responsive-carousel/lib/styles/carousel.min.css")
require("./src/styles/styles.css")
